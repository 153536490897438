// import addresses from '@/config/constants/contracts.js';
import BasicUrl from '@/config/configUrl/configUrl';
var addresses = {}
console.log(BasicUrl.hostUrl.indexOf('happydex.fun')!=-1);

if(BasicUrl.hostUrl.indexOf('happydex.fun')!=-1){
  addresses = {
    jackRouter: '0x5674f6792495b80333487845af57069614b468fa',
    jackFactory: '0xF31480d5edf8f49D6525223516EE05cCeB71B4A7',
  
    jackRouterV2: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    jackFactoryV2: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  };
  
}else{
  addresses = {
    jackRouter: '0xA3eCBD7E1d95eB6662a64DFb96560AC909f58Bf4',
    jackFactory: '0xF31480d5edf8f49D6525223516EE05cCeB71B4A7',
  
    jackRouterV2: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    jackFactoryV2: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  };
}
export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getJackFactoryAddressV2 = () => {
  return getAddress(addresses.jackFactoryV2);
};

export const getJackRouterAddressV2 = () => {
  return getAddress(addresses.jackRouterV2);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
