export const viewHash = hash => {
  window.open('https://bscscan.com/tx/' + hash);
};

export const viewToken = address => {
  window.open('https://bscscan.com/token/' + address);
};


export const  isPC =() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 如果 userAgent 中不包含常见的移动端标识，则判断为 PC 端
  return !/android|iphone|ipad|ipod|windows phone/i.test(userAgent);
}


export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 检查常见的移动端设备标识
  return /android|iphone|ipad|ipod|windows phone/i.test(userAgent);
}