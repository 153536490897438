import request from '../utils/request';
export default {
    //购买
    partnerbuy(data,address,language) {
        return request.request({
            url: '/partner/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    partnerlevelinfo(address,language) {
        return request.request({
            url: '/partner/level-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnerconfigs(address,language) {
        return request.request({
            url: '/partner/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    walletinfobykey(data,address,language) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    partnerlatestpartners(address,language) {
        return request.request({
            url: '/partner/latest-partners',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnerparentlevelinfo(address,language) {
        return request.request({
            url: '/partner/parent-level-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnervotesummary(address,language) {
        return request.request({
            url: '/partner/vote/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnervoteinfo(address,language) {
        return request.request({
            url: '/partner/vote/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnervotestatus(address,language) {
        return request.request({
            url: '/partner/vote/status',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    partnervotedo(data,address,language) {
        return request.request({
            url: '/partner/vote/do',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    gameencryptaddr(data,address,language) {
        return request.request({
            url: '/game/encrypt-addr',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    gameconfig(address,language) {
        return request.request({
            url: '/game/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    gameassettransin(data,address,language) {
        return request.request({
            url: '/game/asset/trans-in',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    gameassettransout(data,address,language) {
        return request.request({
            url: '/game/asset/trans-out',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    gameTransferList(data,address,language) {
        return request.request({
            url: '/game/asset/trans-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    ipoentrymark(data,address,language) {
        return request.request({
            url: '/ipo/entry-mark',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data:data
        })
    },
    ipowalletsummary(address,language) {
        return request.request({
            url: '/ipo/wallet/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    ipoconfig(address,language) {
        return request.request({
            url: '/ipo/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    ipowalletinfo(address,language) {
        return request.request({
            url: '/ipo/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    ipochildrenlist(address,language) {
        return request.request({
            url: '/ipo/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
}