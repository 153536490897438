import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://gameroute.happydex.cc', // 新的接口前缀
  headers: {
    'Content-Type': 'application/json'
  }
});

export default {
    async getNumber(data) {
        try {
          const response = await apiClient.post('/blade-kill/app/web/game/kill/user/getNumber', data);
          return response.data;
        } catch (error) {
          console.error('请求失败:', error);
        }
      }
}
