const HOSTURLOBJECT = window.location
const TESTHTTPTYPE = 'https://'
const TESTHOST = 'happydex.cc'
	//happydex.cc
	//happy.darw.club
	//happydex.fun
	//www.happydex.fun
let configURL = {
	http_url:'',
	initveUrl:'',
	hostUrl:''
}

if(HOSTURLOBJECT.hostname == 'localhost'){

	 configURL = {
		  http_url:`${TESTHTTPTYPE}${TESTHOST}/api`,
		  initveUrl:`${TESTHTTPTYPE}${TESTHOST}`,
		  hostUrl:TESTHOST
	}
}else{
	configURL = {
		  http_url: HOSTURLOBJECT.origin +'/api',
		  initveUrl:HOSTURLOBJECT.origin,
		  hostUrl:HOSTURLOBJECT.host
	}
}
console.log('configURL',HOSTURLOBJECT);

export default configURL