<template>
  <div class="top" :style="BlueListincludes?'color:var( --text-color);':''">
    <van-icon name="down" style="transform: rotate(90deg); " color="#fff"  @click="toprouter()" />
    
  </div>
</template>

<script>
const BlueList = ['ZhuangZhan3','TiBi2','ChoBi1','shandui','shequ','fankui','fankuiadd','fanxiangqin','zhangdan','gongao','fenxiang','xiangqing','fenxiang918']
export default {
  name: '',
   props:{
      router:{
          type:String,
          default:""
      }
   },
  components: {

  },
  data() {
    return {

    }
  },
  computed: {
    BlueListincludes(){
            console.log('this.$route.name',this.$route.name);
            return BlueList.includes(this.$route.name)
        }
  },
  created() {
   
  },
  methods: {
     toprouter (){
        console.log(this.router);
        this.$router.go(-1);
     }
  },
}
</script>

<style lang="less" scoped>
.top{
  
}
  .img{
    width: 19px;
    height: 12px;
  }
</style>