<template>
  <div class="header">
    <div span="12" class="spread">
      <div @click="menuShow" style="margin-right: 5px">
        <van-icon name="bars" />
      </div>
      <!-- <img src="@/assets/img/logo_icon2.png"  style="width: 24px; height: 24px; margin-right: 14px" alt="" /> -->
      <img src="@/assets/img/logo.png" class="spread_img" />
      <span class="spread_text"></span>
      <!-- <img src="@/assets/img/sangang.png" alt="" class="spread_img" style="margin-left: 10px; margin-top: 5px; width: 30px; height: 30px;"  @click="menuShow"> -->
    </div>
    <div span="12" class="rightItem">
      <!-- <img src="@/assets/img/bsclogo(3).png"
          style="width: 60px; height: 24px;"
           alt=""
      class="bsclogo" /> -->
      <div class="addressBox" v-if="account">
        <div class="account">{{ getAccount }}</div>
      </div>
      <button type="primary" class="loginBtn" v-else @click="handleLogin">{{ $t('lang.swap55') }}</button>
      <div class="addressBox">
        <div class="account" style="display: flex; align-items: center">
          <img style="width: 20px; margin-right: 5px; height: 20px" src="@/assets/img/bsc_icon1.png" alt="" v-if="ColorCheckData1 == 'black'" />
          <img style="width: 20px; margin-right: 5px; height: 20px" src="@/assets/img/bsc_icon2.png" alt="" v-else />
          BSC
        </div>
      </div>

      <div class="settings">
        <!-- <div class="language_switching" style="padding-right: 0" @click="switchLanguage">
          <div @click="ColorCheckSwitching">
            <img class="language" src="@/assets/img/write_icon.png" v-if="ColorCheckData1 == 'white'" />
            <img class="language" src="@/assets/img/balck_icon.png" v-else-if="ColorCheckData1 == 'black'" />
          </div>
        </div> -->
        <div class="language_switching" style="padding-right: 0" @click="switchLanguage">
          <div @click="languageSwitching" v-if="ColorCheckData1 == 'black'">
            <img class="language" src="@/assets/img/write_zh.png" v-if="currentLanguage == 'zh-CN'" />
            <img class="language" src="@/assets/img/write_en.png" v-else-if="currentLanguage == 'en-US'" />
          </div>
          <div @click="languageSwitching" v-else>
            <img class="language" src="@/assets/img/black_zh.png" v-if="currentLanguage == 'zh-CN'" />
            <img class="language" src="@/assets/img/black_en.png" v-else-if="currentLanguage == 'en-US'" />
          </div>
        </div>
        <!-- <div v-show="multilingualCondition">
          <div class="multilingual">
            <div v-for="(item, index) of actions" :key="index" @click="languageSwitching(item)" :class="item.text == currentLanguage ? 'special' : ''">
          
              <span :style="currentLanguage == item.imgurl ? 'color:var( --text-color)' : 'color:var( --line-bg-color)'">{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay" @click="handleAction"></div>
        </div> -->
      </div>
    </div>

    <van-popup v-model="show" round position="left" @close="close" class="side">
      <!-- <img src="@/assets/img/x.png" alt="" class="x" @click="close()"> -->
      <div class="menu">
        <div class="user">
          <div class="invitation">
            <div>
              <img src="@/assets/img/invitations.png" class="invitation_img" />
              <span>{{ $t('lang.swap80') }}</span>
            </div>
            <div class="more" @click="toNav('Invitation')">
              <span>{{ $t('lang.swap131') }}</span>
            </div>
          </div>
          <span class="invite_detail">{{ $t('lang.swap271') }}:</span>
          <div class="address">
            <div class="invitation_address">
              <span v-if="parent_id == 0" @click="popupfunopen"> {{ $t('lang.swap272') }}</span>
              <span v-else
                ><span v-if="invite_code == ''"> ----</span><span v-else>{{ linkshow }}</span>
              </span>
            </div>
            <div class="copys" style="opacity: 0.4" v-if="parent_id == 0">{{ $t('lang.swap61') }}</div>
            <div class="copys" v-else @click="copy" :data-clipboard-text="`${invite_linkleft}/#/?invite_code=${invite_code}`">
              <span>{{ $t('lang.swap61') }}</span>
            </div>
          </div>
        </div>

        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1" v-for="(item, index) of menuList" :key="index" title-class="menu_li" :is-link="false">
            <template #title>
              <div @click="toNav(item.router, 'title', item.subordinate.length)" style="width: 100%; display: flex; align-items: center; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <img :src="ColorCheckData1 == 'black' ? item.image : item.image1" class="slot_image" />
                  <span>{{ $t(item.name) }}</span>
                </div>

                <van-icon name="arrow" />
              </div>
              <!-- <li class="line" ></li> -->
            </template>
            <div v-for="(e, i) of item.subordinate" :key="i" class="menu_list" @click="bNav(e.router, e.i)">
              <span>{{ $t(e.name) }}</span>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
    <van-popup v-model="settingShow" round>
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
    <div class="popup" v-show="popupshow">
      <div class="p_main">
        <p class="m_title">{{ $t('lang.d76') }}</p>
        <span>{{ $t('lang.d189') }}:</span>
        <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
        <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
        <img src="@/assets/img/popupx.png" alt="" class="popupx" @click="popupfunclose" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SettingsModal from '@/components/SettingsModal.vue';
import axios from 'axios';
import Clipboard from 'clipboard';
import { invite, coin, homedata, user } from '@/api/index';
import { NetConfig } from '@/config/constants/netWorkConfig.js';
export default {
  name: 'Header',
  components: {
    SettingsModal,
  },
  data() {
    return {
      popupshow: false,
      fromInput: '',
      settingShow: false,
      showPopover: false,
      currentNetWorkDel: {
        text: '',
        logo: '',
      },
      actionsNetWorkConfig: [],
      NetWorkSelect: false,
      price: 0,
      currentLanguage: '',
      actions: [
        { text: 'English', lang: 'en-US', className: 'langPopover', imgurl: require('@/assets/img/en.png') },

        { text: '中文简体', lang: 'zh-CN', className: 'langPopover', imgurl: require('@/assets/img/zh.png') },
      ],
      show: false,

      menuList: [
      {
          image: require('@/assets/img/home_icon.png'),
          image1: require('@/assets/img/home_icon.png'),
          name: 'lang.h1',
          router: 'Index',
          subordinate: [],
        },
        {
          image: require('@/assets/img/swap_icon.png'),
          image1: require('@/assets/img/swap_icon.png'),
          name: 'lang.h3',
          router: '',
          subordinate: [
          { name: 'V1', router: 'Swap' },
          { name: 'V2', router: 'SwapV2' },
          ],
        },
        {
          image: require('@/assets/img/fankui_icon.png'),
          image1: require('@/assets/img/fankui_icon1.png'),
          name: 'lang.h58',
          router: '',
          subordinate: [],
        },
        //下拉菜单
        {
          image: require('@/assets/img/nft_icon.png'),
          image1: require('@/assets/img/nft_icon1.png'),
          name: 'lang.h60',
          subordinate: [],
          router: 'NFTCenter',
        },
        {
          image: require('@/assets/img/game_icon.png'),
          image1: require('@/assets/img/game_icon.png'),
          name: 'lang.h146',
          subordinate: [],
          router: 'gamePage',
        },
        {
          image: require('@/assets/img/konto_icon.png'),
          image1: require('@/assets/img/konto_icon1.png'),
          name: 'lang.h59',
          subordinate: [],
          router: '',
        },

        {
          image: require('@/assets/img/dao_icon.png'),
          image1: require('@/assets/img/dao_icon1.png'),
          name: 'lang.h62',
          subordinate: [],
          router: '',
        },
        {
          image: require('@/assets/img/ai_icon.png'),
          image1: require('@/assets/img/ai_icon1.png'),
          name: 'lang.h61',
          subordinate: [],
          router: '',
        },
      ],

      activeNames: ['0'],
      multilingualCondition: false,
      parent_id: 0,
      invite_linkleft: '',
      invite_code: '',
    };
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData1']),
    getAccount() {
      if (this.account) {
        return '0x...' + this.account.substring(this.account.length - 6, this.account.length);
      } else {
        return '';
      }
    },
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.invite_code}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
  },

  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  created() {
    document.documentElement.dataset.theme = this.ColorCheckData1;
    console.log(this.lang);
    this.actionsNetWorkConfig = NetConfig;
    this.currentNetWorkDel = NetConfig[0];
    console.log('this.currentNetWorkDel', this.currentNetWorkDel);
    this.$store.commit('SETLANG', localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US');
    this.$i18n.locale = this.lang;
    // this.currentLanguage = this.$i18n.locale == 'zh' ? '中文' : 'English';
    for (let i in this.actions) {
      if (this.actions[i].lang == this.$i18n.locale) {
        this.currentLanguage = this.actions[i].lang;
      }
    }

    this.init();
    // axios
    //   .get('https://api.pancakeswap.info/api/v2/tokens/0x25BF35B089DAcB352eadB802a685207d31E23a9F')
    //   .then(res => {
    //     this.price = (res.data.data.price * 1).toFixed(2);
    //   })
    //   .catch(e => { });
  },
  mounted() {},
  methods: {
    popupfunopen() {
      this.popupshow = true;
    },
    popupfunclose() {
      this.popupshow = false;
    },
    NetWorkConfigSwitching(item) {
      this.NetWorkSelect = false;
      if (!item.Isopen) return;
      this.currentNetWorkDel = item;
      //  this.$store.commit('SETNETWORKCINFIG', item);
    },
    postucbin() {
      this.popupshow = false;
      let data = {
        parent_addr: this.fromInput,
      };
      user.postucbind(data, this.account).then(res => {
        console.log(res);
        this.init();
      });
    },
    async init() {
      homedata.gettokenimgurl().then(res => {
        this.invite_linkleft = res.data.domain;
      });

      if (this.account) {
        await this.getucinfo();
        if(this.parent_id == 0){
          await this.yaoqing();
        }
        
      }
    },
    getucinfo() {
      user.getucinfo(this.account).then(res => {
        console.log('w', res);
        this.invite_code = res.data.invite_code;
        this.parent_id = res.data.parent_id;
      });
    },
    yaoqing() {
      let url = window.location.href;

      let obj = url.split('?invite_code=')[1]; //截取问号后面参数

      if (obj != '' && obj != null && obj != undefined) {
        let data = {
          parent_addr: obj,
        };
        user.postucbind(data, this.account).then(res => {
          if (res.code == 200) {
                 this.getucinfo()
          }
        });
      }
    },
    handleLogin() {
      this.$store.dispatch('setWebProvider');
      window.location.reload();
    },
    handleSettingShow() {
      this.settingShow = true;
    },
    handleSettingClose() {
      this.settingShow = false;
    },
    switchNetWorkConfig() {
      this.NetWorkSelect = !this.NetWorkSelect;
    },
    languageSwitching() {
      this.currentLanguage = this.currentLanguage == 'zh-CN' ? 'en-US' : 'zh-CN';
      this.$i18n.locale = this.currentLanguage;
      localStorage.setItem('lang', this.currentLanguage);
      this.$store.commit('SETLANG', this.currentLanguage);
      // this.multilingualCondition = false;
    },
    ColorCheckSwitching() {
      this.$store.commit('SETColorCheckData1', this.ColorCheckData1 == 'black' ? 'white' : 'black');
      document.documentElement.dataset.theme = this.ColorCheckData1 == 'black' ? 'black' : 'white';
    },
    changeLaguages() {
      let lang = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      this.$i18n.locale = lang;
    },
    menuShow() {
      this.show = true;
    },
    close() {
      this.show = false;
      // console.log('close');
    },
    toNav(router, title, length) {
      if (title) {
        if (router != '') {
          if (router == 'chat') {
            window.location.href = 'https://circle.lemishengtai.cn';
          } else {
            this.$router.push({ name: router });
          }
          this.close();
        } else {
          if (length == 0) {
            // this.$toast(this.$t('lang.swap65'));
          }
        }
      } else {
        if (router != '') {
          if (router == 'chat') {
            window.location.href = 'https://circle.lemishengtai.cn';
          } else {
            this.$router.push({ name: router });
          }
          this.close();
        } else {
          // this.$toast(this.$t('lang.swap65'));
        }
      }
    },
    bNav(router, i) {
      if (router != '') {
        if (router == 'chat') {
        } else {
          this.$router.push({ name: router, query: { id: i } });
        }
        this.close();
      } else {
        // this.$toast(this.$t('lang.swap65'));
      }
    },
    //语言弹窗的状态
    switchLanguage() {
      this.multilingualCondition = !this.multilingualCondition;
    },
    handleAction() {
      this.multilingualCondition = false;
    },
    copy() {
      if (this.invite_code == '') return this.$toast('您没有邀请码 !');
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  height: 50px !important;
  // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid #3399ff;
  background: var(--bg-color);
  border-left: 0;
  border-right: 0;
  padding: 0 16px;
  .rightItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #595b5b;

      border-radius: 16px;
      padding: 4px 10px;
      margin-left: 0px;
    }
    .rightIcon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .loginBtn {
      margin-left: 10px;
      width: 100px;
      height: 22px;
      color: #f6bb31ff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
      border-radius: 2px;
      background: none;
      text-align: center;
      border: 1px solid #f6bb31ff;
    }
  }
  .addressBox {
    margin-left: 12px;
    padding: 2px 5px;
    border-radius: 44px;

    border: 1px solid var(--text-color);
    // padding: 5px;
    display: flex;
    align-items: center;
  }
  .walletBox {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: -12px;
    z-index: 10;
    .wallet {
      height: 30px;
      width: 30px;
    }
  }
  .account {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 19.6px;
    color: var(--text-color);

    // box-shadow: 0px 1px 0px 0.5px #3399ff;
  }
  .spread {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-size: 24px;
    color: var(--text-color);
    .spread_img {
      // display: block;
      width: 22px;
      height: 25px;
      margin-right: 5px;
    }
    .spread_text {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
  /deep/ .van-collapse-item,
  .van-hairline--top-bottom {
    border: 0 !important;
    position: static;
  }
  /deep/ .van-collapse-item__content {
    padding: 0;
    position: static;
    background: none !important;
  }
  /deep/ .van-collapse-item {
    padding: 16px 14px !important;
  }
  // /deep/ .van-collapse-item--border::after {
  //   border: 0 !important;
  // }
  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: 0 !important;
  }
  /deep/ .van-cell {
    // border: 2px solid #3399ff;
    background: var(--text-color);
  }
  .side {
    height: 100%;
    width: 80%;
    // background: var( --text-color);
    background: none;

    border: 2rpx solid #ffd0c1;
    .user {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: var(--text-color);
      background: linear-gradient(136.87deg, rgba(23, 189, 186, 1) 0%, rgba(117, 41, 230, 1) 100%);
      // box-shadow: 0px 3px 6px 1px #ffd85e;
      // border: 1px solid #ffd361;
      border-radius: 10px;
      padding: 10px 14px;
      margin: 0 14px 30px;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        margin-bottom: 20px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .invitation_img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        .more {
          font-size: 12px;
          border-radius: 16px;
          // border: 1px solid var( --text-color);
          padding: 2px 12px;
          color: var(--text-color);
        }
      }
      .invite_detail {
        width: 52px;
        height: 15px;
        font-size: 12px;
        color: var(--text-color);
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: var(--text-color);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        margin-top: 5px;
        .invitation_address {
          width: 70%;
          text-align: center;
          background: #ffffff;
          color: #000;
          border-radius: 6px;
          padding: 8px 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .copys {
          color: var(--text-color);
          border-radius: 6px;
          padding: 6px 10px;
          margin-left: 10px;
          border: 1px solid var(--text-color);
          flex: 1;
          text-align: center;

          // width: 58px;
          // height: 27px;
          .span {
            // width: 24px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #6b5200;
          }
        }
      }
    }
  }
  // /deep/ .van-popup {
  //   padding: 0 0 60px;
  // }
  .menu {
    // position: relative;
    height: 100%;
    // overflow: auto;
    background: var(--bg-color);

    border-radius: 0px 16px 16px 0px;
    // overflow: hidden;
    // border: 2px solid #3399ff;
    padding: 14px 0 60px;

    .menu_li {
      font-weight: bold !important;
      font-family: 'HarmonyOSSansSC';

      div {
        background: var(--bg-color);

        font-size: 16px;
        color: var(--text-color);
        font-weight: 600 !important;
        font-family: 'HarmonyOSSansSC';
      }
    }

    .slot_image {
      width: 26px;
      height: 26px;

      margin-right: 12px;
    }
    .menu_list {
      margin: 0 !important;
      
      background: var(--bg-color) !important;
      border: none !important ;
      outline: none !important;
      font-family: 'HarmonyOSSansSC';
      color: var(--text-color);
      font-size: 14px;
      padding: 8px 0 8px 40px;
    }
  }
  .settings {
    display: flex;
    position: relative;

    font-weight: bold;

    font-family: YouSheBiaoTiYuan-Regular, YouSheBiaoTiYuan;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      border-radius: 16px;
    }
    .language {
      width: 24px;
      height: 24px;
    }
    .multilingual {
      position: absolute;
      top: 34px;
      right: 0;
      font-size: 14px;
      width: 76px;

      border-radius: 2px;
      display: flex;
      flex-direction: column;

      background: var(--box-bg-color);
      box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
      border-radius: 6px;
      text-align: center;
      padding: 10px;
      z-index: 999;
      div {
        text-align: center;
        padding: 6px 0;
        flex: 1;

        font-size: 12px;
        font-weight: 700;
        font-family: 'PingFang SC';
      }
      .special {
        color: #5c8bf9;
      }
      .IsOpenCss {
        opacity: 0.4;
      }
    }
    .overlay {
      position: fixed;
      z-index: 998;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.144);
      margin-top: 48px;
    }
  }
}
@media screen and (min-width: 1000px) {
  .header {
    .side {
      height: 100%;
      width: 20%;
      border: 2rpx solid #ffd0c1;
    }
  }
}
@media screen and (min-width: 700px) {
  // .header {
  //   .spread {
  //     display: none;
  //   }
  // }
}
.line {
  width: 250px;
  height: 1px;
  background: #27364c;
  position: absolute;
  bottom: 0;
  left: 12px;
}
.disbbox {
  width: 100%;

  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
  div {
    width: 50%;

    display: flex;

    align-items: center;
    margin-bottom: 30px;
    img {
      width: 38px;
      height: 38px;
      margin-right: 10px;
    }
    span {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}
.x {
  margin-left: 20px;
  width: 20px;
  height: 20px;
  margin-top: 20px;
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease;
  .p_main {
    width: 288px;
    height: 248px;
    border-radius: 10px;
    padding: 10px;
    background: var(--text-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 20px;
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: linear-gradient(-43.7deg, #1a70fdff 0%, #26c3ffff 100%);
    }
    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }
    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}
.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}
.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: #e8e8e8ff !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
  }
}
</style>