//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0xb61e1493cb2cbbf2e3e89872c6095aa000d5db32",
      "name": "HAP",
      "symbol": "HAP",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/HAP.png')
    },
    {
      "chainId": 56,
      "address": "0x93dabd824adf1fea0a2d5869c26dd4035cd8b6b7",
      "name": "GOC",
      "symbol": "GOC",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/GOC.png')
    },
]

export const BNBV2list = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0xd63b34b48af447f36be3f738819446424487c397",
      "name": "HapNeiro",
      "symbol": "HapNeiro",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/HapNeiro.jpg')
    },
]




