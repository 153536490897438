<template>
  <div style="height: 100%">
    <div class="liquidity_list">
      <div>
        <div class="headWrap">
          <div>
            <span>{{ $t("lang.swap98") }}</span>
          </div>
          <div class="headWrap_img" v-if="ColorCheckData1 == 'black'">
            <div>
              <img src="@/assets/img/set.png"
                   @click="handleSettingShow"
                   alt
                   class="iconSet" />
            </div>
            <div>
              <img src="@/assets/img/record.png"
                   @click="handleRecordShow"
                   alt
                   class="iconRecord" />
            </div>
          </div>
          <div class="headWrap_img" v-else>
            <div>
              <img src="@/assets/img/set1.png"
                   @click="handleSettingShow"
                   alt
                   class="iconSet" />
            </div>
            <div>
              <img src="@/assets/img/record1.png"
                   @click="handleRecordShow"
                   alt
                   class="iconRecord" />
            </div>
          </div>
        </div>
        <div class="remove">
          <span>{{ $t("lang.swap99") }}</span>
        </div>
      </div>

      <div class="liquidity_explain">
        <div class="liquidity_data">
          <van-collapse v-model="activeNames"
                        v-for="(item, index) of list"
                        :key="index">
            <van-collapse-item :name="index">
              <template #title>
                <div class="title">
                  <img class="busd"
                       src="@/assets/img/busd.png"
                       alt />
                  <img class="busd"
                       src="@/assets/img/busd.png"
                       alt />
                  <span>{{ item.fromSymbol }}</span>/
                  <span>{{ item.toSymbol }}</span>
                </div>
                <div>{{ significantDigits((item.lpBalance) / Math.pow(10,18)) }}</div>
              </template>
              <div class="content">
                <div class="detail">
                  <div class="detail_left">
                    <img :src="item.fromLogoURI"
                         class="busd" />
                    <span>{{ $t("lang.swap103") }} {{ item.fromSymbol }}:</span>
                  </div>
                  <div class="detail_amount">
                    <span>{{ (item.fromBalance*1).toFixed(9) }}</span>
                  </div>
                </div>
                <div class="detail">
                  <div class="detail_left">
                    <img :src="item.toLogoURI"
                         class="busd" />
                    <span>{{ $t("lang.swap103") }} {{ item.toSymbol }}:</span>
                  </div>
                  <div class="detail_amount">
                    <span>{{ (item.toBalance*1).toFixed(9) }}</span>
                  </div>
                </div>
                <div class="detail">
                  <div class="detail_left">
                    <span>{{ $t("lang.swap96") }}</span>
                  </div>
                  <div class="detail_amount">
                    <span v-if="item.percentage < 0.01">{{ percentageMin }}</span>
                    <span v-else-if="item.percentage >= 100">
                      {{
                    percentageMax
                    }}
                    </span>
                    <span v-else>{{ item.percentage }}%</span>
                  </div>
                </div>
                <div class="mobility_button">
                  <van-button type="primary"
                              class="swapBtn"
                              @click="handleSwap(item)">{{ $t("lang.swap104") }}</van-button>
                </div>
                <div @click="liquidity('2')"
                     class="mobility">
                  <span>＋ Add liquidity instead</span>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="tips">
          <div v-show="mobilityList == []">
            <span>{{ $t("lang.swap100") }}</span>
          </div>
          <div class="interval">
            <span>{{ $t("lang.swap101") }}</span>
          </div>
          <div class="view_other"
               @click="liquidity('1')">
            <span>{{ $t("lang.swap102") }}</span>
          </div>
        </div>
      </div>
      <div class="button_swapBtn">
        <van-button type="primary"
                    class="swapBtn"
                    @click="liquidity('2')">+ {{ $t("lang.swap94") }}</van-button>
      </div>
    </div>

    <van-popup v-model="transactionRecordShow"
               round
               position="bottom">
      <transaction-record @listenClose="handleTransactionRecordClose" />
    </van-popup>
    <van-popup v-model="settingShow"
               round
               position="bottom">
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
  </div>

</template>

<script>
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import SettingsModal from '@/components/SettingsModal.vue';
import { getJackPairContract, getJackFactoryContractV2 } from '@/utils/contractHelp';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { mapState } from 'vuex';

import DEFAULT_TOKEN_DEFI_LIST from '@/config/constants/tokenDEFIList.json';
import DEFAULT_TOKEN_SWAN_LIST from '@/config/constants/tokenSWANList.json';
import { BNB } from '@/config/constants/bnb';
import {BNBV2list} from '@/config/constants/tokenlist.js';
import { homedata } from '../../api';
export default {
  data () {
    return {
      transactionRecordShow: false,
      settingShow: false,
      mobilityList: [],
      imgurl:'',
      activeNames: ['0'],
      list: [],
      storeLpList: [],
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      switchVersionShow: '0',

    };
  },
  created () {
    homedata.gettokenimgurl().then(res =>{
      this.imgurl =res.data.image_domain

    })
    this.getStoreList()
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath','ColorCheckData1']),
  },
  methods: {
    getStoreList () {
      try {
        this.storeLpList = localStorage.getItem('lpListV2') ? JSON.parse(localStorage.getItem('lpListV2')) : [];
        if (this.storeLpList) {
          this.getLpList(this.storeLpList);
        }
      } catch (e) {
        this.storeLpList = [];
      }
    },
    watchLpList () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        //e.key : 是值发生变化的key
        //例如 e.key==="token";
        //e.newValue : 是可以对应的新值
        if (e.key === 'lpList') {
          _this.getStoreList()
        }
      });
    },
    async getLpList (storeLpList) {
      this.list = [];
      if (storeLpList.length != 0) {
        for (let i of storeLpList) {
          let detail = {};
          let jackFactoryContract = getJackFactoryContractV2();
          let pairAddress = await jackFactoryContract.methods.getPair(i.fromAddress, i.toAddress).call();
          let jackPairContract = getJackPairContract(pairAddress);
          let lpBalance = await jackPairContract.methods.balanceOf(this.account).call();
          let showLpBalance = formatAmount(lpBalance) * 1;
          let allBalance = await jackPairContract.methods.totalSupply().call();
          let scale = accDiv(lpBalance, allBalance); //当前比例
          let reserves = await jackPairContract.methods.getReserves().call(); //获取当前总额
          let fromBalance;
          let toBalance;
          if (i.fromAddress.toLowerCase() < i.toAddress.toLocaleLowerCase()) {
            fromBalance = (reserves._reserve0 / Math.pow(10, i.fromDecimals)) * scale;
            toBalance = (reserves._reserve1 / Math.pow(10, i.toDecimals)) * scale;
          } else {
            fromBalance = (reserves._reserve1 / Math.pow(10, i.fromDecimals)) * scale;
            toBalance = (reserves._reserve0 / Math.pow(10, i.toDecimals)) * scale;
          }
          detail['lpAddress'] = pairAddress; //lp地址
          detail['lpBalanceShow'] = showLpBalance; //lp展示余额
          detail['lpBalance'] = lpBalance; //lp余额
          detail['fromAddress'] = i.fromAddress; //form地址
          detail['fromSymbol'] = i.fromSymbol;
          detail['fromDecimals'] = i.fromDecimals;
          detail['toDecimals'] = i.toDecimals;
          detail['fromBalance'] = fromBalance; //from 余额
          detail['toAddress'] = i.toAddress; //to地址
          detail['toSymbol'] = i.toSymbol;
          detail['toBalance'] = toBalance; //to 余额
          detail['percentage'] = accDiv(lpBalance, allBalance) * 100; //份额


          let currencyList = [...BNBV2list,BNB];
          for (let i = 0; i < currencyList.length; i++) {
            if (currencyList[i].address == detail['fromAddress']) {
              detail['fromLogoURI'] = currencyList[i].logoURI
            }
            if (currencyList[i].address == detail['toAddress']) {
              detail['toLogoURI'] = currencyList[i].logoURI
            }
          }
          if (lpBalance != 0) {
            this.list.push(detail);
          }
        }
      }
    },
    significantDigits,
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    handleRecordShow () {
      this.transactionRecordShow = true;
    },
    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },
    //
    liquidity (i) {
      if (i == '2') {
        this.$router.push({ name: 'LiquidityV2' });
      } else if (i == '1') {
        this.$router.push({ name: 'importMiningV2' });
      }
    },
    handleSwap (item) {
      this.$router.push({ name: 'RemoveV2', query: { item: JSON.stringify(item) } });
    },
  },
  components: {
    TransactionRecord,
    SettingsModal,
  },
};
</script>

<style lang="less" scoped>
.liquidity_list {
  padding-bottom: 30px;
  background: var( --box-bg-color);
  // width: 100%;
  min-height: 80vh;
  border-radius: 30px 30px 0 0;
  // box-shadow: 0px 6px 32px rgba(13, 13, 13, 0.08);
  // border: 2px solid #f2f2f2;
  // border: 1px solid #707070;
  // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);

  // padding: 10px 0 18px;
  margin-bottom: 10px;
  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: var( --text-color);
    padding: 8px 16px 0;
    .headWrap_img {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 14px;
      div {
        margin-left: 10px;
      }
      .iconSet {
        display: block;
        width: 40px;
        height: 40px;
        // margin-right: 23px;
        cursor: pointer;
      }
      .iconRecord {
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      .iconHash {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }
  .remove {
    font-size: 12px;
    color: var( --text-color);
    padding: 0 16px;
  }
  .liquidity_explain {
    // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

    padding: 0 0 34px;
    margin: 20px;
    .liquidity_data {
      /deep/ .van-hairline--top-bottom {
        overflow: hidden;
        // background: #000 !important;
        // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

        border-radius: 15px;
        padding: 0;
        position: static;
        margin-bottom: 20px;
      }
      /deep/ .van-collapse-item__title {
        padding: 12px 16px !important;
        font-size: 16px;
        color: var( --text-color);
        font-weight: bold;

        background: var( --line-bg-color) !important;
      }
      /deep/ .van-collapse-item__content {
        background: var( --line-bg-color) !important;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: var( --text-color);
        font-weight: bold;
        margin-bottom: 6px;
      }
      .busd {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 6px;
      }
      .content {
        .detail {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          font-weight: bold;
          .detail_left {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: var( --text-color);
            // margin-left: 8px;
          }
          .detail_amount {
            font-size: 14px;
            color: var( --text-color);
          }
        }
        .mobility_button {
          .swapBtn {
            width: 100%;
            font-size: 14px;
            color: #333333;
            border-radius: 87px;
            background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
            border: 0;
            font-weight: bold;
            padding: 4px 0;
            // margin-top: 22px;
          }
        }
        .mobility {
          font-size: 18px;
          font-weight: bold;
          color: var( --text-color);
          // background: linear-gradient(to right, #356ef2, #834bca, #f87f17);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          text-align: center;
          margin-top: 16px;
        }
      }
    }

    .tips {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: var( --text-color);
      background:  var( --line-bg-color);
      border-radius: 15px;
      padding: 50px 0 40px;
      .view_other {
        font-size: 18px;
        padding: 8px 60px;
        border: 1px solid transparent;
        border-radius: 10px;
        border: 1px solid rgba(182, 133, 255, 1);
        background-image: #00baad;
        // margin-top: 52rpx;
        span {
          color: rgba(182, 133, 255, 1);
        }
      }
      .interval {
        margin: 0 0 40px;
      }
    }
  }
}
.button_swapBtn {
  // padding: 0 24px;
  margin: 26px 20px 20px;
  .swapBtn {
    width: 100%;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    border-radius: 87px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    padding: 26px 0 !important;
  }
}
/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>